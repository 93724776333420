import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/queueNewCheck.css';
import Empty from "../utils/Empty";
import Mobile from "../utils/Mobile";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueNewCheck({locale, dialog, Dialog, type, user, setVisit}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [country, setCountry] = useState(() => {
        if (user.locale === 'en') {
            return 'us';
        } else if (user.locale === 'es') {
            return 'mx';
        } else {
            return 'us';
        }
    });

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Next = () => {
        if (type === 'email' && !email) {
            toast({message: locale.queueNewCheck.a, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (type === 'mobile' && !mobile) {
            toast({message: locale.queueNewCheck.b, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (type === 'whatsapp' && !mobile) {
            toast({message: locale.queueNewCheck.p, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            let number = '';
            if (type === 'mobile' || type === 'whatsapp') {
                number = mobile.replace(/[()\s-]/g, '');
            }

            const message = {
                type: 'wss',
                path: 'queue-patient',
                action: 'get',
                data: {
                    email: type === 'email' ? email : null,
                    mobile: (type === 'mobile' || type === 'whatsapp') ? number : null,
                    country: (type === 'mobile' || type === 'whatsapp') ? country : null,
                    type: type
                }
            };
            wsCall(message);
        }
    }

    const GetQueuePatient = useCallback(data => {
        if (data.patient) {
            setVisit(data.patient);
        }
        if (data.error && data.queue) {
            toast({message: locale.queueNewCheck.m, color: 'danger', display: 'bottom', duration: 3000});
        }
        Dialog({view: 'queueNewVisit'});
    }, [Dialog, locale.queueNewCheck.m]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'queue-patient') {
            GetQueuePatient(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetQueuePatient, setWsResponse]);

    return (
        <Popup className="cs-queue-new-check-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} headerText={locale.queueNewCheck.c} buttons={[{text: locale.queueNewCheck.d, cssClass: 'cs-queue-new-check-popup-save', handler: 'close'}, {text: locale.queueNewCheck.e, cssClass: 'cs-queue-new-check-popup-save', handler: () => Next()}]} isOpen={dialog.view === 'queueNewCheck'} onClose={CloseDialog}>
            <div className="cs-queue-new-check-helper">
                {type === 'email' &&
                    <p>{locale.queueNewCheck.f}</p>
                }
                {type === 'mobile' &&
                    <p>{locale.queueNewCheck.g}</p>
                }
                {type === 'whatsapp' &&
                    <p>{locale.queueNewCheck.o}</p>
                }
            </div>
            {type === 'email' &&
                <Input inputStyle="underline" label={locale.queueNewCheck.h} labelStyle="floating" type="email" name="email" value={email} onChange={ev => {setEmail(ev.target.value) }} />
            }
            {(type === 'mobile' || type === 'whatsapp') &&
                <div className="mbsc-grid cs-queue-new-check-grid">
                    <div className="mbsc-row">
                        <div className="mbsc-col-4 cs-queue-new-check-inputs-left">
                            <Select
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="underline"
                                label={locale.queueNewCheck.j}
                                labelStyle="floating"
                                rows={locale.queueNewCheck.k.length}
                                display="anchored"
                                touchUi={false}
                                value={country}
                                data={locale.queueNewCheck.k}
                                disabled={mobile.length !== 0}
                                onChange={(event) => setCountry(event.value)}
                            />
                        </div>
                        <div className="mbsc-col-8 cs-queue-new-check-inputs-right">
                            <Input className="cs-queue-new-check-inputs-right-input" label={type === 'mobile' ? locale.queueNewCheck.i : locale.queueNewCheck.n} inputStyle="underline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile, country: country})} />
                        </div>
                    </div>
                </div>
            }
        </Popup>
    );
}

export default QueueNewCheck;
