import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Select, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview, NavItem, TabNav} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import 'dayjs/locale/es'
import '../styles/queue.css';
import Empty from '../utils/Empty';
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import QueueWait from "../blocks/QueueWait";
import QueueIn from "../blocks/QueueIn";
import QueueTransfer from "../blocks/QueueTransfer";
import QueueNewMenu from "../dialogs/QueueNewMenu";
import QueueNewVisit from "../dialogs/QueueNewVisit";
import QueueNewCheck from "../dialogs/QueueNewCheck";
import QueueVisitTransfer from "../dialogs/QueueVisitTransfer";
import QueueVisit from "../dialogs/QueueVisit";
import QR from "../shared/QR";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function Queue({locale, width, navigation, Navigation, configuration, dialog, Dialog, user, setPatient, refresh, setRefresh, setVisit, visit}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [type, setType] = useState('');
  const [qr, setQr] = useState({});
  const [location, setLocation] = useState(() => {
    if (user.location && configuration.locations.some(item => item.value === user.location)) {
      return user.location;
    } else if (!user.location && configuration.locations.length !== 0) {
      return configuration.locations[0].value;
    } else {
      return '';
    }
  });

  const Location = data => {
    setRefresh(true);
    setLocation(data);

    const message = {
      type: 'wss',
      path: 'user',
      action: 'update',
      data: {
        location: data
      }
    };
    wsCall(message);
  }

  const UpdateVisitPatient = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queue.f, color: 'info', duration: 1000, display: 'bottom'});
    }
    setRefresh(false);
  }, [locale.queue.f]);

  const GetPatient = useCallback(data => {
    if (data.patient) {
      setPatient(data.patient);
      Navigation({page: 'patient', tab: 'visit', save: true});
    }
  }, [Navigation]);

  const PutShortener = useCallback(data => {
    if (data.qr) {
      setQr(data.qr);
    }
    if (data.qr && !dialog.view) {
      Dialog({view: 'sharedQr'});
    }
  }, [Dialog]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'shortener') {
      PutShortener(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PutShortener, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'patient') {
      GetPatient(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, GetPatient, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-patient') {
      UpdateVisitPatient(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateVisitPatient, setWsResponse]);

  useEffect(() => {
    if (configuration.locations.length === 1) {
      Navigation({menu: locale.queue.c});
    } else if (configuration.locations.length > 1 && configuration.queue.transfer) {
      Navigation({menu: locale.queue.d});
    }
  }, []);

  return (
      <div className="mbsc-grid cs-queue-main">
        <div className={width > 768 ? "mbsc-row cs-queue-container" : "mbsc-row cs-queue-container-xs"}>
          <div className="mbsc-col cs-queue-title cs-queue-title-input">
            {configuration.locations.length <= 1 &&
                <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                  {locale.queue.b}
                </p>
            }
            {configuration.locations.length > 1 &&
                <Select
                    animation="slide-down"
                    dropdown={false}
                    inputStyle="box"
                    rows={configuration.locations.length > 10 ? 10 : configuration.locations.length}
                    display="anchored"
                    touchUi={false}
                    value={location}
                    data={configuration.locations}
                    onChange={(event) => Location(event.value)}
                />
            }
          </div>
        </div>
        <div className="mbsc-row">
          {width > 768 &&
              <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-queue-left">
                {navigation.menu && navigation.menu.length !== 0 &&
                    <Listview
                        theme="ios"
                        themeVariant="light"
                        select="single"
                        itemType={event => Item(event, navigation)}
                        data={navigation.menu}
                        onItemTap={event => Menu(event.index, Navigation, navigation)}
                    />
                }
              </div>
          }
          <div className="mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-queue-right">
            {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                <TabNav theme="ios" themeVariant="light" display='inline' >
                  {navigation.menu.map((item) => {
                    return (
                        <NavItem selected={navigation.tab === item.name} onClick={(ev) => Menu(item.name, Navigation, navigation)} >
                          <span className={navigation.tab === item.name ? 'mbsc-bold cs-queue-nav' : 'mbsc-bold'} >{item.label}</span>
                        </NavItem>
                    );
                  })}
                </TabNav>
            }
            {navigation.tab === 'wait' && !refresh &&
                <QueueWait
                    locale={locale}
                    Dialog={Dialog}
                    setVisit={setVisit}
                    location={location}
                    setQr={setQr}
                />
            }
            {navigation.tab === 'in' && !refresh &&
                <QueueIn
                    locale={locale}
                    Dialog={Dialog}
                    type={type}
                    setVisit={setVisit}
                    location={location}
                />
            }
            {navigation.tab === 'transfer' && !refresh &&
                <QueueTransfer
                    locale={locale}
                    configuration={configuration}
                    Dialog={Dialog}
                    type={type}
                    setVisit={setVisit}
                    location={location}
                />
            }
            {dialog.view === 'queueNewMenu' &&
                <QueueNewMenu
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setType={setType}
                    configuration={configuration}
                />
            }
            {dialog.view === 'queueNewCheck' &&
                <QueueNewCheck
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    type={type}
                    user={user}
                    setVisit={setVisit}
                />
            }
            {dialog.view === 'queueNewVisit' &&
                <QueueNewVisit
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    type={type}
                    configuration={configuration}
                    width={width}
                    visit={visit}
                    location={location}
                    setRefresh={setRefresh}
                />
            }
            {dialog.view === 'queueVisitTransfer' &&
                <QueueVisitTransfer
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    setRefresh={setRefresh}
                    visit={visit}
                    configuration={configuration}
                />
            }
            {dialog.view === 'queueVisit' &&
                <QueueVisit
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    configuration={configuration}
                    width={width}
                    visit={visit}
                    setRefresh={setRefresh}
                    navigation={navigation}
                    user={user}
                    setQr={setQr}
                    qr={qr}
                />
            }
            {dialog.view === 'sharedQr' && !Empty(qr) &&
                <QR
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    qr={qr}
                />
            }
          </div>
        </div>
      </div>
  );
}

export default Queue;
