import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/queueWait.css';
import dayjs from "dayjs";
import {faUsersMedical, faQrcode, faAt, faMobileScreenButton, faCirclePhone, faClipboardList, faBrainCircuit} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import User from "../utils/User";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueWait({locale, Dialog, setVisit, location, setQr}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [queueWait, setQueueWait] = useState([]);
    const [loading, setLoading] = useState(true);

    const QR = () => {
        setQr({});

        const message = {
            type: 'wss',
            path: 'shortener',
            action: 'put',
            data: {
                path: 'queueWait',
                type: 'qr',
                location: location,
                title: locale.queueWait.e
            }
        };
        wsCall(message);
    }

    const Open = data => {
        setVisit(data);
        Dialog({view: 'queueVisit', tab: 'visit'});
    }

    const Patient = data => {
        setVisit(data);

        const message = {
            type: 'wss',
            path: 'patient',
            action: 'get',
            data: {
                patient: data.user
            }
        };
        wsCall(message);
    }

    const Item = data => {
        const itemDate = dayjs(parseInt(data.item.timestampArrival)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.timestampArrival}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-queue-wait-list" onClick={(ev) => Open(data.item)} >
                        {data.item.type === 'mobile' &&
                            <FontAwesomeIcon className="cs-queue-wait-list-icon-mobile" icon={faMobileScreenButton} />
                        }
                        {data.item.type === 'whatsapp' &&
                            <FontAwesomeIcon className="cs-queue-wait-list-icon-whatsapp" icon={faCirclePhone} />
                        }
                        {data.item.type === 'email' &&
                            <FontAwesomeIcon className="cs-queue-wait-list-icon-email" icon={faAt} />
                        }
                        <div className="cs-queue-wait-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.firstName && data.item.firstName} {data.item.middleName && data.item.middleName} {data.item.lastName && data.item.lastName} {data.item.paternalName && data.item.paternalName} {data.item.maternalName && data.item.maternalName} | {User(data.item)}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-queue-wait-item-txt">
                                {locale.queueWait.f} {itemDate}
                            </div>
                        </div>
                        {data.item.triage.length !== 0 &&
                            <FontAwesomeIcon className="cs-queue-wait-icon-info" icon={faBrainCircuit} />
                        }
                    </div>
                </div>
                <div className="mbsc-col-auto cs-queue-wait-list-button">
                    <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => Patient(data.item)} >
                        <FontAwesomeIcon className="cs-queue-wait-icon-right" icon={faClipboardList} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const Queue = data => {
        const message = {
            type: 'wss',
            path: 'queue',
            action: 'get',
            data: {
                status: 'wait',
                location: location
            }
        };
        wsCall(message);
    }

    const GetQueue = useCallback(data => {
        if (data.queue) {
            setQueueWait(data.queue);
        }
        if (loading) {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'queue') {
            GetQueue(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetQueue, setWsResponse]);

    useEffect(() => {
        Queue();
    }, []);

    return (
        <>
            <div className="mbsc-row cs-queue-wait-menu">
                <div className="mbsc-col">
                    <div className="mbsc-row">
                        <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => QR()} >
                            <FontAwesomeIcon className="cs-queue-wait-icon" icon={faQrcode} /> {locale.queueWait.c}
                        </Button>
                    </div>
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row">
                        <Button id="queueNewMenu" color="primary" variant="flat" className="mbsc-bold" onClick={() => Dialog({view: 'queueNewMenu'})} >
                            <FontAwesomeIcon className="cs-queue-wait-icon" icon={faUsersMedical} /> {locale.queueWait.b}
                        </Button>
                    </div>
                </div>
            </div>
            {loading &&
                <Loading />
            }
            {queueWait.length === 0 && !loading &&
                <div className="mbsc-row mbsc-justify-content-center cs-queue-wait-section">
                    <div className="mbsc-padding">
                        <p className="mbsc-bold mbsc-txt-muted">{locale.queueWait.a}</p>
                    </div>
                </div>
            }
            {queueWait.length !== 0 && !loading &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={queueWait}
                />
            }
        </>
    );
}

export default QueueWait;
