import React, {useCallback, useState, useContext} from 'react';
import {Popup, Input, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/headerAccount.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function HeaderAccount({locale, dialog, Dialog, select}) {
    const {restCall} = useContext(NetworkContext);
    const [account, setAccount] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Select = () => {
        if (!account) {
            toast({message: locale.headerAccount.j, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (account) {
            const message = {
                type: 'rest',
                path: 'account-configuration',
                action: 'get',
                data: {
                    account: account
                }
            };
            restCall(message);
        }
    }

    const Close = () => {
        if (select) {
            return false;
        } else if (!select && window.localStorage.account) {
            return true;
        } else if (!select && !window.localStorage.account) {
            return false;
        }
    }

    const SmartDocFitness = () => {
        window.location.href = 'https://smartdocfitness.com';
    }

    return (
        <Popup className="cs-header-account-popup" width={600} display="center" closeOnOverlayClick={Close()} closeOnEsc={Close()} headerText={select ? locale.headerAccount.n : locale.headerAccount.a} buttons={select ? [{text: locale.headerAccount.g, cssClass: 'cs-header-account-popup-save', handler: () => SmartDocFitness()}, {text: locale.headerAccount.h, cssClass: 'cs-header-account-popup-save', handler: () => Select()}] : [{text: locale.headerAccount.f, cssClass: 'cs-header-account-popup-save', handler: () => Select()}]} isOpen={dialog.view === 'headerAccount'} onClose={CloseDialog}>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.b}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.c}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.d}</p>
            </div>
            <Input inputStyle="underline" label={locale.headerAccount.e} labelStyle="floating" type="text" name="account" value={account} onChange={ev => {setAccount(ev.target.value) }} />
            {select &&
                <div className="cs-header-account-helper">
                    <p>{locale.headerAccount.l} <span className="mbsc-bold">{locale.headerAccount.h}</span> {locale.headerAccount.m}</p><br />
                </div>
            }
            {select &&
                <div className="cs-header-account-helper">
                    <p>{locale.headerAccount.i} <span className="mbsc-bold">{locale.headerAccount.g}</span> {locale.headerAccount.k}</p>
                </div>
            }
        </Popup>
    );
}

export default HeaderAccount;
