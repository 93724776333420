import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import '../styles/patientMedicalHistory.css';
import {faClipboard} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientMedicalHistory({locale, setPatient, patient, setDraft}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [pmh, setPmh] = useState(patient.pmh);
    const [visits, setVisits] = useState([]);

    const Change = data => {
        setPmh(data);

        if (data && data.length % 10 === 0) {
            const item = {...patient};
            item.pmh = data;
            setPatient(item);

            const message = {
                type: 'wss',
                path: 'patient',
                action: 'update',
                data: {
                    patient: patient.id,
                    pmh: data
                }
            };
            wsCall(message);
        }
    }

    const Blur = () => {
        setDraft(false);

        const item = {...patient};
        item.pmh = pmh;
        setPatient(item);

        const message = {
            type: 'wss',
            path: 'patient',
            action: 'update',
            data: {
                patient: patient.id,
                pmh: pmh
            }
        };
        wsCall(message);
    }

    const Summary = () => {
        const message = {
            type: 'wss',
            path: 'pmh-summary',
            action: 'get',
            data: {
                user: patient.user
            }
        };
        wsCall(message);
    }

    const Visits = () => {
        const message = {
            type: 'wss',
            path: 'visits',
            action: 'get',
            data: {
                user: patient.user
            }
        };
        wsCall(message);
    }

    const Open = data => {
        const message = {
            type: 'wss',
            path: 'queue-visit',
            action: 'get',
            data: {
                user: patient.user,
                id: data.id
            }
        };
        wsCall(message);
    }

    const Item = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-medical-history-list" onClick={(ev) => Open(data.item)}>
                        <FontAwesomeIcon className="cs-patient-medical-history-list-icon" icon={faClipboard} />
                        <div className="cs-patient-medical-history-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.reason}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-medical-history-item-txt">
                                {locale.patientMedicalHistory.c} {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-immunizations-list-button" />
            </div>
        </li>;
    }

    const GetPmhSummary = useCallback(data => {
        if (data.summary) {

        }
    }, []);

    const GetVisits = useCallback(data => {
        if (data.visits) {
            setVisits(data.visits);
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'pmh-summary') {
            GetPmhSummary(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetPmhSummary, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'visits') {
            GetVisits(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVisits, setWsResponse]);

    useEffect(() => {
        Visits();
    }, []);

    return (
        <div className="cs-patient-medical-history-page">
            <div className="mbsc-row cs-patient-medical-history-section cs-patient-medical-history-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-medical-history-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientMedicalHistory.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button disabled={visits.length === 0} color="primary" variant="flat" className="cs-patient-medical-history-button mbsc-bold mbsc-txt-muted" onClick={() => Summary()} >
                        {locale.patientMedicalHistory.b}
                    </Button>
                </div>
            </div>
            <CKEditor
                id="editor-focus"
                config={{
                    toolbar: ['bold', 'italic', 'link'],
                    blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                }}
                editor={BalloonBlock}
                data={pmh}
                onChange={(event, editor) => Change(editor.getData())}
                onBlur={() => pmh !== patient.pmh ? Blur() : null}
            />
            {visits.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={visits}
                />
            }
            <div className={visits.length === 0 ? "mbsc-row cs-patient-medical-history-footer-empty" : "mbsc-row cs-patient-medical-history-footer"} />
        </div>
    );
}

export default PatientMedicalHistory;
